if (document.querySelector('[data-search-input="true"]')) {
  const inputEl = document.querySelector('[data-search-input="true"]');
  const table = document.getElementById(inputEl.getAttribute('data-tableId'));

  if (table) {
    inputEl.addEventListener('keyup', () => {
      const filter = inputEl.value.toUpperCase();
      const tr = table.getElementsByTagName('tr');
      for (var i = 0; i < tr.length; i++) {
        const td = tr[i].getElementsByTagName('td')[0];
        if (td) {
          if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = '';
          } else {
            tr[i].style.display = 'none';
          }
        }
      }
    });
  } else {
    console.error(
      'Add to input search the data attribute tableId with the id of the table element'
    );
  }
}
