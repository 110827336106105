import $ from 'jquery';
import Quill from 'quill';

window.addmicro = addmicro;
window.createmacro = createmacro;
window.addmicrowithcontent = addmicrowithcontent;

$(document).ready(function () {
  $(window).keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
});

// add new macro
if (document.getElementById('addMacro')) {
  $('#addMacro').click(function () {
    const macronum = $('.addmicro').length;
    const macropid = 'macropid' + macronum.toString();
    const newMacro =
      '<div style="margin-bottom: 20px;"class="form-group macroinput panel panel-default"><div class="panel-body addmicro temp"><div style="padding-bottom: 50px"><div class="col-sm-8"><input required type="text"class="form-control macro"name="macro" placeholder="Macro"></div><div class="col-sm-4"><button type="button" id="btnsom" class="btn btn2 btntemp">Add Micro</button></div></div></div</div>';
    $('#macros').append(newMacro);
    $('.temp').attr('id', macropid);
    $('.temp').removeClass('temp');

    const btnclick = `addmicro('${macropid}','${macronum}')`;
    $('.btntemp').attr('onclick', btnclick);
    $('.btntemp').removeClass('btntemp');
  });
}

function createmacro(string) {
  const macronum = $('.addmicro').length;
  const macropid = 'macropid' + macronum.toString();
  const newMacro =
    '<div style="margin-bottom: 20px;"class="form-group macroinput panel panel-default"><div class="panel-body addmicro temp"><div style="padding-bottom: 50px"><div class="col-sm-8"><input required type="text"class="form-control macro macrotemp"name="macro" placeholder="Macro"></div><div class="col-sm-4"><button type="button" id="btnsom" class="btn btn2 btntemp">Add Micro</button></div></div></div</div>';
  $('#macros').append(newMacro);
  $('.temp').attr('id', macropid);
  $('.temp').removeClass('temp');

  const btnclick = `addmicro('${macropid}','${macronum}')`;
  $('.btntemp').attr('onclick', btnclick);
  $('.btntemp').removeClass('btntemp');

  $('.macrotemp').attr('value', string);
  $('.macrotemp').removeClass('macrotemp');
}

// add new micro
const quills = [];

function addmicro(macroplane, id) {
  macroplane = macroplane.toString();

  const quillnum = $('.quill').length;
  const quillid = 'quill' + quillnum.toString();

  const newWrite =
    '<div class ="row" style="margin-top: 20px;"><div class ="col-sm-10"><input type="hidden" class="textsomething temptext" name="something" value="hello"><div class="quillz" ></div></div><div class ="col-sm-2"><input required type="text"class="form-control tempscore"name="" placeholder="score"></div></div>';
  $('#' + macroplane).append(newWrite);
  $('.quillz').attr('id', quillid);
  $('.quillz').removeClass('quillz').addClass('quill');

  const nameinp = 'textsomething[' + id + ']';

  $('.temptext').attr('name', nameinp);
  $('.temptext').removeClass('temptext');

  const namescore = 'score[' + id + ']';
  $('.tempscore').attr('name', namescore);
  $('.tempscore').removeClass('tempscore');

  quills.push(
    new Quill('#quill' + quillnum.toString(), {
      theme: 'snow',
    })
  );
}

// add quillz content

function addmicrowithcontent(macroplane, id, content, score) {
  macroplane = macroplane.toString();

  const quillnum = $('.quill').length;
  const quillid = 'quill' + quillnum.toString();

  const newWrite =
    '<div class ="row" style="margin-top: 20px;"><div class ="col-sm-10"><input type="hidden" class="textsomething temptext" name="something" value="hello"><div class="quillz" ></div></div><div class ="col-sm-2"><input required type="text"class="form-control tempscore"name="" placeholder="score"></div></div>';
  $('#' + macroplane).append(newWrite);
  $('.quillz').attr('id', quillid);
  $('.quillz').append(content);
  $('.quillz').removeClass('quillz').addClass('quill');

  const nameinp = 'textsomething[' + id + ']';

  $('.temptext').attr('name', nameinp);
  $('.temptext').removeClass('temptext');

  const namescore = 'score[' + id + ']';
  $('.tempscore').attr('name', namescore);
  $('.tempscore').attr('value', score);
  $('.tempscore').removeClass('tempscore');

  quills.push(
    new Quill('#quill' + quillnum.toString(), {
      theme: 'snow',
    })
  );
}
