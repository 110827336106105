// Import main scss file
import '../css/main.scss';

// Import features
import './new-comparator';
import './search-comparator/seatch-input';
import './new-scoresheet/new-scoresheet';
import './chart/piec-label';
import './hovering-menu/hovering-menu';
import './chart/graphs';

// Import jquery and make it global for bootstrap
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Load bootstrap in async fashon
import('bootstrap').then(() => console.info('Boostrap loaded'));

// Manage tooltip
// TODO: Move it into specific features file
$(document).ready(() => {
  if (document.querySelector('[data-toggle="tooltip"]')) {
    $('[data-toggle="tooltip"]').tooltip();
  }
});
