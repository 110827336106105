import $ from 'jquery';

window.nextPrev = nextPrev;

let currentTab = 0; // Current tab is set to be the first tab (0)

$(document).ready(() => {
  if (document.getElementsByClassName('tab').length > 0) {
    showTab(currentTab); // Display the current tab
  }
});

function showTab(n) {
  // This function will display the specified tab of the form ...
  const x = document.getElementsByClassName('tab');
  // Hide the all the tabs
  for (
    let index = 0;
    index < document.getElementsByClassName('tab').length;
    index++
  ) {
    x[index].style.display = 'none';
  }
  // Show only the one for the current step
  x[n].style.display = 'block';
  // ... and fix the Previous/Next buttons:
  if (n === 0) {
    document.getElementById('prevBtn').style.display = 'none';
  } else {
    document.getElementById('prevBtn').style.display = 'inline';
  }
  if (n === x.length - 1) {
    document.getElementById('nextBtn').innerHTML = 'See your results';
  } else {
    document.getElementById('nextBtn').innerHTML = 'Next';
  }
  // ... and run a function that displays the correct step indicator:
  fixStepIndicator(n);
}

function nextPrev(n) {
  // This function will figure out which tab to display
  const x = document.getElementsByClassName('tab');
  // Exit the function if any field in the current tab is invalid:
  if (n == 1 && !validateForm()) return false;
  // Hide the current tab: //change on the fly
  try {
    x[currentTab].style.display = 'none';
  } catch {
    console.log('no tab to display');
  }
  // Increase or decrease the current tab by 1:
  currentTab = currentTab + n;
  $(window).scrollTop(0);
  // if you have reached the end of the form... :
  if (currentTab >= x.length) {
    // ...the form gets submitted:
    document.getElementById('form').submit();
    return false;
  }
  // Otherwise, display the correct tab:
  showTab(currentTab);
}

function validateForm() {
  // This function deals with validation of the form fields
  let x;
  let y;
  let i;
  let valid = true;
  x = document.getElementsByClassName('tab');
  y = x[currentTab].getElementsByTagName('input');
  // A loop that checks every input field in the current tab:
  for (i = 0; i < y.length; i++) {
    // If a field is empty...
    if (y[i].value == '') {
      // add an "invalid" class to the field:
      y[i].className += ' invalid';
      // and set the current valid status to false:
      valid = false;
    }
  }
  // If the valid status is true, mark the step as finished and valid:
  if (valid) {
    document.getElementsByClassName('step')[currentTab].className += ' finish';
  }
  return valid; // return the valid status
}

function fixStepIndicator(n) {
  // This function removes the "active" class of all steps...
  let i;
  const x = document.getElementsByClassName('step');
  for (i = 0; i < x.length; i++) {
    x[i].className = x[i].className.replace(' active', '');
  }
  // ... and adds the "active" class to the current step:
  x[n].className += ' active';
}
