import $ from 'jquery';

$(document).ready(function () {
  $(window).keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
});

let numberOfMacros = 1;
let numberOfMicros = 1;

// add new macro
if ($('#btn1')) {
  $('#btn1').click(function () {
    const newmacro =
      '<div class="form-group macroinput"><p>Macro ' +
      numberOfMacros +
      '</p><input required type="text"class="form-control macro" name="macro" placeholder="macro"></div>';
    const newmicro =
      ' <div class="form-group allmicro"><input required "type="text" class=form-control micro" name="micro" placeholder="Micro"><input type="text" class="form-control description" name="descriptionm" placeholder="Description"></div> ';
    const microplane =
      '<div class="microplane"><p>Micros for macro ' +
      numberOfMacros +
      ':</p><div class="microplanee"></div></div>';
    if (numberOfMicros > 1) {
      const corrNum = $('.allmicro').length / $('.macro').length;
      $('#macro').append(newmacro);
      numberOfMacros = numberOfMacros + 1;

      $('#micros').append(microplane);
      for (let i = 0; i < corrNum; i++) {
        const microlast = $('.microplanee').last();
        microlast.append(newmicro);
      }
    } else {
      $('#macro').append(newmacro);
      $('#micros').append(microplane);
      numberOfMacros = numberOfMacros + 1;
    }
  });
}

// add new micro
if ($('#btn2')) {
  $('#btn2').click(function () {
    const newmicro =
      ' <div class="form-group allmicro"><input required type="text" class="form-control micro" name="micro" placeholder="Micro"><input type="text" class="form-control description" name="descriptionm" placeholder="Description"></div> ';

    if ($('.macroinput').length === 0) {
      alert('Please input macros first');
    } else {
      $('.microplanee').append(newmicro);
      numberOfMicros = numberOfMicros + 1;
    }
  });
}

// Delete macro
if ($('#btn3')) {
  $('#btn3').click(function () {
    $('.macroinput').last().remove();
    $('.microplane').last().remove();
    numberOfMacros = numberOfMacros - 1;
    if ($('.macroinput').length === 0) {
      numberOfMicros = 1;
    }
  });
}

if ($('#btn4')) {
  $('#btn4').click(function () {
    const micropos = $('.allmicro').length / $('.macro').length;
    if ($('.allmicro').length / $('.macro').length === 1) {
      alert(
        'Sorry it is not possible to delete all the micros before deleting all macros'
      );
    } else {
      for (let i = 0; i < $('.macroinput').length; i++) {
        $('.allmicro')[micropos * i].remove();
      }
      numberOfMicros = numberOfMicros - 1;
    }
  });
}
